import { ThemeProvider } from "@cr/material-ui-theme-provider";
import { Column } from "@cr/react-basics";
import { Button, Card, CardActions, CardContent, makeStyles, Typography } from "@material-ui/core";
import GlobalStyle from "app/util/globalStyle";
import { observer } from "mobx-react-lite";
import React from "react";

const useStyles = makeStyles(theme => ({
  card: {
    zIndex: 2,
    maxWidth: 600
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  cardTitle: {
    textAlign: "left",
    marginBottom: 24
  }
}));

interface UserTimeoutDialogProps {
  onRefreshSession: () => void;
}

export const UserTimeoutDialog = observer(({ onRefreshSession }: UserTimeoutDialogProps) => {
  const classes = useStyles();

  return (
    <ThemeProvider skipGlobalStyles>
      <GlobalStyle styles={{ body: { overflow: "hidden" } }} />
      <Column minHeight="100vh" className="gradient-bg fadeIn75" data-testid="externalLogout">
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" className={classes.cardTitle}>
              Session Timed Out
            </Typography>
            <Typography>Your session has timed out of CR Enterprise due to inactivity.</Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button onClick={onRefreshSession} color="primary" data-testid="refreshSession" variant={"contained"}>
              Refresh session
            </Button>
          </CardActions>
        </Card>
      </Column>
    </ThemeProvider>
  );
});
