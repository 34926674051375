import Permission from "permissions/models/permission";

export abstract class PermissionDisplayFilter {
  static filterPermissions(permission: Permission) {
    return (
      permission.id in BillingManagerPermissions ||
      permission.id in BundlesPermissions ||
      permission.id in ClaimsPermissions ||
      permission.id in ContactsPermissions ||
      permission.id in MessagingPermissions ||
      permission.id in NotesPermissions ||
      permission.id in PayrollPermissions ||
      permission.id in PermissionsPermissions ||
      permission.id in ReportingPermissions ||
      permission.id in ResourcesPermissions ||
      permission.id in SchedulingPermissions ||
      permission.id in TasksPermissions ||
      permission.id in MobilePermissions ||
      permission.id in DataCenterPermissions
    );
  }
}

/** Permissions for Billing Manager module. */
export enum BillingManagerPermissions {
  Access = 33,
  ManageTimesheetsForBilling = 34,
  ManageSettings = 35,
  ManageOrgLabels = 36,
  UseOrgLabels = 37,
  ManageOrgServiceCodesLabels = 38,
  UseOrgServiceCodesLabels = 39,
  AccessReceivables = 82,
  AccessSales = 83,
  ManagePayments = 84,
  AccessInvoices = 86,
  AccessBilling = 87,
  AccessTimesheets = 88,
  CreateInvoices = 90,
  DownloadBilling = 91,
  AccessReconciliationModule = 117,
  AccessPayments = 132,
  DownloadTimesheets = 133,
  ManageTimesheets = 134,
  AllowtimesheetsConversionMods = 135,
  AccessAccrued = 150,
  AccessDraftTimesheets = 187,
  DeleteDraftTimeSheets = 188,
  SubmitDraftTimesheets = 191,
  ManageDraftTimesheets = 192,
  DeleteTimesheets = 189,
  VisitVerification = 309,
  EditVisitVerification = 325,
  CredentialsConversionOverride = 340,
  AuthorizationsOverride = 351,
  GracePeriodOverride = 352,
  ManagePaymentOverrides = 353,
  CollectProviderSignatures = 365,
  NoteCheckAI = 368
}

export enum DataCenterPermissions {
  AutomatedImport = 303,
  Export = 304,
  Import = 305,
  Access = 306,
  AutomatedExport = 307,
  CrElementsImport = 335
}

/** Permissions for Bundles module */
export enum BundlesPermissions {
  Access = 32,
  ManageOrgLabels = 54,
  UseOrgLabels = 55,
  AddLearningTrees = 56,
  AddSessions = 57,
  AccessVBMapp = 93,
  AddVBMapp = 94,
  AccessRBTCourse = 95,
  AccessProgressReport = 99,
  CourseGroupsBillingAdmin = 100,
  ManageSessions = 103,
  ManageRoutines = 104,
  CourseGroupsSeatAdmin = 121,
  ManageNoteTemplates = 130,
  ManageCriteriaTemplates = 131,
  ManageOrgABCTemplates = 154,
  AssignToClients = 161,
  AssignToProviders = 162,
  ManageSessionNoteTemplates = 310,
  AddSessionNoteTemplates = 311,
  DeleteSessionNoteTemplates = 312,
  AddSessionNotes = 317,
  EditSessionNotes = 318,
  DeleteSessionNotes = 319,
  UnlockSessionNotes = 320,
  PrimaryAssessor = 360,
  SecondaryAssessor = 361,
  AssessmentsReviewer = 362,
  SelectSessionNotes = 364
}

/** Permissions for Claims module */
export enum ClaimsPermissions {
  Access = 42,
  ManageOrgLabels = 43,
  UseOrgLabels = 44,
  ManageInsuranceSettings = 48,
  CreateClaims = 89
}

/** Permissions for Contacts module */
export enum ContactsPermissions {
  Access = 2,
  AddEmployeesUsingCustomForms = 3,
  AddClientsUsingCustomForms = 4,
  AddProviders = 5,
  AddGenericsUsingCustomForms = 6,
  BulkConnectEmployeesAndClients = 7,
  BulkConnectEmployeesAndEmployees = 8,
  ImportEmployees = 9,
  ImportClients = 10,
  ExportContacts = 11,
  ManageEmployees = 12,
  ManageOrganizationLabels = 13,
  UseOrganizationLabels = 14,
  ViewMetaDataSettings = 49,
  EditMetaData = 50,
  ManageOrganizationForms = 51,
  ViewInvoicesChargesPayments = 58,
  AddEmployees = 118,
  AddClients = 119,
  AddGenerics = 120,
  RequestInsuranceVerifications = 156,
  ManageInsuranceVerificationDetails = 157,
  ManageOwnBasicInfo = 160,
  HideEmployeefromClientPortal = 166,
  ViewAddressManagement = 336,
  MangeAddressManagement = 338,
  ViewEmployees = 341,
  OrganizationSettings = 344,
  ManageActivityCallLogSettings = 348,
  ViewActivityHistory = 354,
  ManageClientPayors = 355,
  ViewClientProfile = 356,
  ManageClientProfile = 357,
  AddNewActivity = 358,
  ManageActivities = 359,
  ManageConnectionsAuditLogExternal = 366,
  ClientPaymentAccount = 367
}

/** Permissions for Messaging module */
export enum MessagingPermissions {
  AccessMessaging = 18,
  AccessChat = 140,
  SendToClients = 141,
  SendToProviders = 142
}

/** Permissions for Notes module */
export enum NotesPermissions {
  Access = 70,
  ManageNotesTemplates = 71,
  GoalCreator = 165,
  AssociateNoteTemplatesToServiceCodes = 313,
  CopyNotes = 315,
  SelectNotes = 316,
  DeleteLockedNote = 347
}

/** Permissions for Payroll module */
export enum PayrollPermissions {
  Access = 21,
  RunPayroll = 122,
  ManageEmployees = 123,
  TimeOffAdministrator = 124,
  PTOAccess = 125,
  ManageEmployeesBenefits = 128,
  AccessAssets = 151,
  ManageOrgLabels = 152,
  UseOrgLabels = 153,
  ManageAssets = 158,
  ManagePTO = 163
}

/** Permissions for Permissions module. */
export enum PermissionsPermissions {
  Access = 1,
  ManageGroups = 46
}

/** Permissions for Reporting module */
export enum ReportingPermissions {
  Access = 19,
  ManageSettings = 20,
  DesignerSeatManagement = 195
}

/** Permissions for Resources module */
export enum ResourcesPermissions {
  Access = 22,
  UploadFiles = 23,
  ManageAuthTemplates = 24,
  ManageAuth = 25,
  ManageOrgLabels = 26,
  UseOrgLabels = 27,
  ManageDocumentTemplates = 67,
  ViewDocumentReport = 68,
  ManageFile = 69,
  AccessBasicAuth = 80,
  AccessFullAuth = 81,
  ClassifyDocuments = 126,
  DownloadFiles = 164,
  AccessImport = 343
}

/** Permissions for Scheduling module */
export enum SchedulingPermissions {
  Access = 40,
  ManageOwnSchedule = 41,
  ManageClientAvailability = 45,
  ManageEmployeePermissions = 47,
  SyncApptTypes = 102,
  ManageOwnAvailability = 105,
  ManageOrgLabels = 106,
  UseOrgLabels = 107,
  AllowCancellingOneOff = 108,
  AllowDeletingOneOff = 109,
  ManageOwnApptTypes = 110,
  ManageOwnApptLocations = 111,
  ManageEmployeeApptTypes = 112,
  ManageEmployeeApptLocations = 113,
  ManageEmployeeAvailability = 114,
  ManageClientSettings = 115,
  ManageClientLocations = 116,
  EditConvertedAppts = 127,
  CancelRecurringAppts = 143,
  DeleteRecurringAppts = 144,
  BypassLockSettings = 159,
  OverrideAuthLimitations = 175,
  ScheduleOverlappingAppts = 176,
  OverrideRequiredCredentials = 222,
  AppointmentAllowEditTimeZone = 190,
  SchedulingOptimization = 308,
  CopyNotes = 315,
  SelectNotes = 316,
  DraftSchedule = 339,
  ManageCancellationReasons = 345,
  ManageAdditionalSettings = 346,
  ManageOrganizationOfficeLocations = 349
}

/** Permissions for Tasks module. */
export enum TasksPermissions {
  Access = 15,
  ManageOrgLabels = 16,
  UseOrgLabels = 17,
  TaskAdmin = 98,
  ManageSettings = 145,
  ShareTaskTemplates = 350
}

/** Permissions for Mobile module. */
export enum MobilePermissions {
  ProviderSignatures = 180,
  Access = 181,
  MapAccess = 182,
  AiChatbot = 369
}
